import React, { useEffect } from 'react';
import uuid from 'uuid/v4';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import FeatureBlock from '../../../components/FeatureBlock';
import Container from '../../../components/UI/Container';
import ImageList from '../../../components/ImageList';
import Particles from '../Particle';
import { useCookie } from '@use-hook/use-cookie';
import Input from 'reusecore/src/elements/Input';
import { socialEuroOutline } from 'react-icons-kit/ionicons/socialEuroOutline';
import {ic_keyboard_arrow_right} from 'react-icons-kit/md/ic_keyboard_arrow_right'

import axios from 'axios';


import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { Icon } from 'react-icons-kit';

import BannerWrapper, {
  InputWrapper,
  InputError,
  PlusWrapper,
  DiscountLabel
} from './ctaSection.style';

import * as yup from 'yup';

import { useForm } from 'react-hook-form';

import CorriereLogo from "../../../assets/image/saas/corriere-della-sera-logo.jpg";
import PagamentiDigitaliLogo from "../../../assets/image/saas/pagamenti-digitali-logo.gif";
import ItaliaOggiLogo from "../../../assets/image/saas/italia-oggi-logo.png";


const CtaSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
  listContentStyle
}) => {
  return (
    <BannerWrapper id="cta_section">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {/* <DiscountLabel>
              <Text content="25% Discount" {...discountAmount} />
              <Text content="on every first project " {...discountText} />
            </DiscountLabel> */}
            <FeatureBlock
              title={
                <Heading content="Confronta i POS e risparmia" {...title} />
              }
              description={
                <Text
                  htmlContent="Confrontiamo <b>gratuitamente</b> le migliori offerte POS per permetterti di scegliere facilmente la più adatta alla
                  tua attività. Puoi risparmiare <b>fino a 9.000 € l’anno</b>!"
                  {...description}
                />
              }
            />
            <Button
              id="step-1"
              href="/step-2"
              title="CONFRONTA LE OFFERTE"
              {...btnStyle}
              pulse
              icon={<Icon icon={ic_keyboard_arrow_right} size={20}/>}
            />
            <Box flexBox justifyContent="space-between" width={[1, 1, '85%', '65%']} mb={[8,8,8,'50px']}>
              <PlusWrapper>
                <Icon
                  icon={checkmark}
                  className="price_list_icon"
                  size={13}
                  style={{
                    color: '#18d379',
                    marginRight: '5px'
                  }}
                />
                <Text content={"100% gratuito"} {...listContentStyle} />
              </PlusWrapper>
              <PlusWrapper>
                <Icon
                  icon={checkmark}
                  className="price_list_icon"
                  size={13}
                  style={{
                    color: '#18d379',
                    marginRight: '5px'
                  }}
                />
                <Text content={"Meno di 3 minuti"} {...listContentStyle} />
              </PlusWrapper>
              <PlusWrapper>
                <Icon
                  icon={checkmark}
                  className="price_list_icon"
                  size={13}
                  style={{
                    color: '#18d379',
                    marginRight: '5px'
                  }}
                />
                <Text content={"Nessun impegno"} {...listContentStyle} />
              </PlusWrapper>
              <PlusWrapper>
                <Icon
                  icon={checkmark}
                  className="price_list_icon"
                  size={13}
                  style={{
                    color: '#18d379',
                    marginRight: '5px'
                  }}
                />
                <Text content={"Risultato immediato"} {...listContentStyle} />
              </PlusWrapper>
            </Box>
          </Box>
        </Box>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <Text
              content="Parlano di noi:"
              mb={1}
              color="#343d48cc"
            />
            <ImageList images={[
              {
                src: CorriereLogo,
                width: '180px',
                href: 'https://milano.corriere.it/notizie/cronaca/20_agosto_16/onda-startup-innovativecosi-sfidiamo-crisi-covid-e8c52b3e-decc-11ea-a8ef-59f191bcf6be.shtml'
              },
              {
                src: ItaliaOggiLogo,
                width: '120px',
                href: 'https://www.italiaoggi.it/news/pos-e-concorrenza-sui-costi-2475650'
              },
              {
                src: PagamentiDigitaliLogo,
                width: '200px',
                href: 'https://www.pagamentidigitali.it/payment-innovation/pos-ecco-quanto-costa/'
              },
            ]} />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

CtaSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object
};

CtaSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '100%', '100%']
  },
  title: {
    fontSize: ['42px', '42px', '40px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
    as: 'h1'
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    width: [1, 1, 1, '75%'],
    mb: ['20px', '20px', '30px']
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5167db',
    ml: '18px'
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0'
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    width: ['100%', 'auto'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
    mb: [8]
  }
};

export default CtaSection;
