import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper
} from 'common/src/containers/Saas/saas.style';
import Navbar from 'common/src/containers/Saas/Navbar';
import CtaSectionTwo from 'common/src/containers/Saas/CtaSectionTwo';
import HowItWorksSection from 'common/src/containers/Saas/TimelineSection';
import Footer from 'common/src/containers/Saas/Footer';
import TrialSection from 'common/src/containers/Saas/TrialSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title="Confronta le offerte POS e risparmia"
          description="Calcola online costi e commissioni delle migliori offerte POS e scegli la più conveniente. Puoi risparmiare fino a 9.000€ l’anno!"
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar button={false} menu={false}/>
            </DrawerProvider>
          </Sticky>
          <CtaSectionTwo />
          <HowItWorksSection />
          <TrialSection
            titleText="Scegli il POS più conveniente!"
            contentText="È completamente gratuito. Nessun vincolo. 3 minuti e pochi dati per risparmiare."
          />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
